import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body p-10 p-lg-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overview = _resolveComponent("Overview")!
  const _component_ThemeColor = _resolveComponent("ThemeColor")!
  const _component_Spacing = _resolveComponent("Spacing")!
  const _component_FontSize = _resolveComponent("FontSize")!
  const _component_LineHeight = _resolveComponent("LineHeight")!
  const _component_Opacity = _resolveComponent("Opacity")!
  const _component_WidthAndHeight = _resolveComponent("WidthAndHeight")!
  const _component_ZIndex = _resolveComponent("ZIndex")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Overview),
      _createVNode(_component_ThemeColor),
      _createVNode(_component_Spacing),
      _createVNode(_component_FontSize),
      _createVNode(_component_LineHeight),
      _createVNode(_component_Opacity),
      _createVNode(_component_WidthAndHeight),
      _createVNode(_component_ZIndex)
    ])
  ]))
}